import React, { useRef } from 'react'
import classnames from 'classnames'
import AriaModal from 'react-aria-modal'
import IconTextButton from '@gestalt/common/src/components/ui_elements/IconTextButton'

import './Modal.scss'

const Modal = React.memo(
  ({
    visible = true,
    sentiment,
    children,
    close_modal,
    is_closable = true,
    closes_on_escape = true,
    className = null,
    titleText = 'default',
    children_props,
    fullscreen = false,
    innerRef = null,
    ...props
  }) => {
    const ref = useRef()
    if (!visible) return null
    return (
      <AriaModal
        ref={innerRef || ref}
        titleText={titleText}
        onExit={(e) => {
          if (!visible || !is_closable) return

          if (
            (e instanceof KeyboardEvent &&
              (innerRef || ref).current?.container?.nextElementSibling ===
                null) || // Close topmost modal only
            e.target.className === 'ModalUnderlay' // Click on underlay
          ) {
            e.stopPropagation()
            close_modal()
          }
        }}
        underlayClickExits={visible && is_closable}
        escapeExits={visible && is_closable && closes_on_escape}
        verticallyCenter
        includeDefaultStyles={false}
        underlayClass='ModalUnderlay'
        dialogClass={classnames('Modal', sentiment, className, { fullscreen })}
        getApplicationNode={() => document.querySelector('.App')}
        {...props}
      >
        {typeof children === 'function' ? children(children_props) : children}
        {is_closable && close_modal && (
          <div className='buttons'>
            <IconTextButton
              onClick={() => close_modal()}
              type='secondary'
              sentiment='negative'
              title={'Close' + (closes_on_escape ? ' [Esc]' : '')}
              icon='close'
            />
          </div>
        )}
      </AriaModal>
    )
  }
)

export default Modal
