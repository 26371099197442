import React, { createContext, useContext } from 'react'
import { User, UserManager } from 'oidc-client-ts'
import { AuthContextProps } from 'react-oidc-context'
import { useSessionStorageState } from '@gestalt/common/src/hooks/useLocalStorageState'

export function authentication_mocked(): boolean {
  return process.env.UI_OIDC_AUTHORITY?.toLowerCase() === 'mock'
}

const DEFAULT_ROLES = [
  'offline_access',
  'admin',
  'default-roles-gestalt',
  'uma_authorization',
]

const MOCK_USER = new User({
  profile: {
    exp: 1800000000,
    iat: 1714482000,
    iss: 'https://localhost/realms/gestalt',
    aud: 'ui',
    sub: 'neuer',
    typ: 'ID',
    email_verified: false,
    roles: DEFAULT_ROLES,
    name: 'Michael Neuer',
    preferred_username: 'neuer',
    given_name: 'Michael',
    locale: 'en',
    family_name: 'Neuer',
    email: 'neuer@example.com',
  },
  access_token: "Sorry, this doesn't REALLY work...",
  token_type: 'Bearer',
})

export const AuthMockContext = createContext<AuthContextProps>(null)

export function get_mock_auth_context_props(
  user: null,
  signin: (args?: any) => Promise<User>,
  signout: (args?: any) => Promise<void>
): AuthContextProps {
  const user_manager = new UserManager({
    authority: '',
    client_id: '',
    redirect_uri: '',
  })
  return {
    user: user,
    isLoading: false,
    isAuthenticated: user !== null,

    activeNavigator: null,
    error: null,

    settings: null,
    events: user_manager.events,
    querySessionStatus: async (args) => null,
    revokeTokens: async () => {},
    startSilentRenew: () => {},
    stopSilentRenew: () => {},
    clearStaleState: async () => {},
    removeUser: async () => {
      signout({})
    },
    signinPopup: signin,
    signinSilent: signin,
    signinRedirect: async () => {
      signin({})
    },
    signinResourceOwnerCredentials: signin,
    signoutRedirect: signout,
    signoutPopup: signout,
    signoutSilent: signout,
  }
}

/**
 * useAuth hook similar which can mock a user
 *
 * @export
 * @returns {AuthContextProps}
 */
export function useAuthMock(): AuthContextProps {
  return useContext(AuthMockContext)
}

export const AuthMockProvider = ({ children }) => {
  const [user, set_user] = useSessionStorageState(
    null,
    `oidc.user:${process.env.UI_OIDC_AUTHORITY}:${process.env.UI_OIDC_CLIENT_ID}`
  )

  const signin = async (args: any) => {
    const roles = prompt(
      'Authentication is mocked. Which roles would you like to have?',
      DEFAULT_ROLES.join(',')
    )
    if (roles === null) return null
    const u = MOCK_USER
    u.profile.roles = roles.split(',')
    set_user(u)
    return u
  }

  const signout = async (args: any) => {
    set_user(null)
  }

  return (
    <AuthMockContext.Provider
      value={get_mock_auth_context_props(user, signin, signout)}
    >
      {children}
    </AuthMockContext.Provider>
  )
}
