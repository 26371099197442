import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
  forwardRef,
} from 'react'
import classnames from 'classnames'
import Modal from './Modal'
import NewButton from './NewButton'
import { useAsyncModal } from '../../hooks/useModal'

const BaseAsyncButton = forwardRef(function BaseAsyncButton(
  {
    disabled = false,
    onClick = null, // (e, {on_progress, signal})
    onError = null,
    onErrorConfirmed = null,
    onAsyncRunningChanged = null,
    onAsyncProgressChanged = null,
    show_modal_on_exception = true,
    className = null,
    progress = null,
    cancelable = true,
    children,
    ...additional_props
  },
  ref
) {
  const [async_running, set_async_running] = useState(false)
  const [internal_progress, set_internal_progress] = useState(null)
  const [abort_signal_consumed, set_abort_signal_consumed] = useState(false)
  const [show_error_modal, error_modal_props] = useAsyncModal()
  const is_unmounted_ref = useRef(false)

  const abort_controller = useRef(null)

  useEffect(() => {
    return () => {
      // console.log('UNMOUNT', additional_props.title)
      is_unmounted_ref.current = true
      abort_controller.current && abort_controller.current.abort() // Cancel onClick on unmount
      onAsyncRunningChanged && onAsyncRunningChanged(false)
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    onAsyncRunningChanged && onAsyncRunningChanged(async_running)
  }, [onAsyncRunningChanged, async_running])
  useEffect(() => {
    onAsyncProgressChanged && onAsyncProgressChanged(internal_progress)
  }, [onAsyncProgressChanged, internal_progress])

  const on_button_click = async (e) => {
    if (disabled || async_running || !onClick) return
    if (cancelable) {
      abort_controller.current && abort_controller.current.abort() // Cancel last onClick
      abort_controller.current = new AbortController()
    } else {
      abort_controller.current = null
    }

    set_abort_signal_consumed(false)
    set_async_running(true)
    set_internal_progress(-1) // -> Negative values mean "indeterminate" (e.g. spinning indicator)
    try {
      await onClick(e, {
        on_progress: (p) => set_internal_progress(p),
        get signal() {
          if (abort_controller.current) {
            set_abort_signal_consumed(true)
            return abort_controller.current.signal
          }
          return undefined
        },
      })
    } catch (error) {
      // console.log('ERROR: ', error, additional_props.title)
      onError && onError(error)
      if (error && show_modal_on_exception) {
        try {
          await show_error_modal({ error })
        } catch {
        } finally {
          // console.log('ERROR RESOLVED', additional_props.title)
          onErrorConfirmed && onErrorConfirmed(error)
        }
      }
    }

    if (!is_unmounted_ref.current) {
      // console.log('END', additional_props.title)
      abort_controller.current && abort_controller.current.abort() //
      set_abort_signal_consumed(false)
      set_internal_progress(null)
      set_async_running(false)
    }
    // console.log('REALEND', additional_props.title)
  }

  const actual_progress =
    progress === null || progress === undefined ? internal_progress : progress
  const has_progress = actual_progress !== null && actual_progress !== undefined
  const effectivly_disabled =
    disabled || (async_running && !abort_signal_consumed)

  return (
    <>
      <button
        ref={ref}
        type='button'
        className={classnames(className, {
          disabled: effectivly_disabled,
          async_running,
          has_progress,
          indeterminate: has_progress && actual_progress < 0,
          cancelable: abort_signal_consumed,
        })}
        disabled={effectivly_disabled}
        onClick={
          async_running && abort_signal_consumed
            ? () => abort_controller.current?.abort()
            : on_button_click
        }
        {...additional_props}
      >
        {children({
          async_running,
          progress: actual_progress,
          has_progress,
          cancelable: abort_signal_consumed,
        })}
      </button>
      <Modal {...error_modal_props} sentiment='negative'>
        {({ resolve, error }) => (
          <>
            {error?.response?.data?.error?.exception ? (
              <>
                <header>
                  <header>Backend Error</header>
                  <footer>{error.response.data.error.exception.type}</footer>
                </header>
                <main>{error.response.data.error.exception.text}</main>
              </>
            ) : (
              <>
                <header>
                  <header>Error</header>
                </header>
                <main>
                  {typeof error === 'string' ? (
                    error
                  ) : (
                    <pre>{error?.stack}</pre>
                  )}
                </main>
              </>
            )}
            <footer>
              <NewButton
                type='secondary'
                title='Close'
                sentiment='neutral'
                onClick={resolve}
              >
                Close
              </NewButton>
            </footer>
          </>
        )}
      </Modal>
    </>
  )
})

export default BaseAsyncButton
