import React, { PureComponent } from 'react'
// import { ReactComponent as Logo } from './assets/sidebar_icon_users.svg'

export default class AsyncSVG extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      Component: null,
      err: null,
    }
  }

  componentDidMount() {
    if (!this.state.Component) {
      if (this.props.src.startsWith('@framework/')) {
        import(`../assets/${this.props.src.replace('@framework/', '')}`)
          .then((mod) => this.setState({ Component: mod.default }))
          .catch((err) => this.setState({ err }))
      } else if (this.props.src.startsWith('@common/')) {
        import(
          `../../../common/src/assets/${this.props.src.replace('@common/', '')}`
        )
          .then((mod) => this.setState({ Component: mod.default }))
          .catch((err) => this.setState({ err }))
      } else if (this.props.src.startsWith('@project/')) {
        import(
          `@gestalt/project/src/assets/${this.props.src.replace(
            '@project/',
            ''
          )}`
        )
          .then((mod) => this.setState({ Component: mod.default }))
          .catch((err) => this.setState({ err }))
      }
    }
  }

  render() {
    const { Component, err } = this.state

    if (err) {
      return <div />
    } else if (!Component) {
      return <div />
    }
    return <Component />
  }
}
