import React from 'react'
import ReactDOM from 'react-dom'

import './index.css'
import AsyncComponent from './components/AsyncComponent'
import { import_mod_from_project_structure } from './utils/dynamic_imports'

function get_entrypoint_module_path(): string {
  if (!process.env.BASEUI_CONFIG) {
    return './entrypoints/legacy'
  }
  const baseui_config = JSON.parse(process.env.BASEUI_CONFIG)
  return baseui_config.entrypoint.startsWith('common') ||
    baseui_config.entrypoint.startsWith('framework')
    ? baseui_config.entrypoint
    : '@gestalt/project/src/' + baseui_config.entrypoint
}

const entrypoint_module = () =>
  import_mod_from_project_structure(get_entrypoint_module_path())

ReactDOM.render(
  <React.StrictMode>
    <AsyncComponent
      moduleProvider={entrypoint_module}
      componentSelector={(mod) => mod.App}
      render
    />
  </React.StrictMode>,
  document.getElementById('root')
)

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
