import React, { useState, useCallback, useMemo, useRef } from 'react'
import Modal from '@gestalt/common/src/components/ui_elements/Modal'
import NewButton from '@gestalt/common/src/components/ui_elements/NewButton'

// TODO: Fix bug where an error modal inside another modal will close both on a click anywhere

export default function useModal(close_callback) {
  const [visible, set_visible] = useState(false)

  const show = useCallback(() => set_visible(true), [])
  const hide = useCallback(() => set_visible(false), [])
  const close = useCallback(() => {
    if (close_callback) {
      close_callback()
    }
    hide()
  }, [])

  const RenderModal = useMemo(
    () =>
      ({ children, ...props }) =>
        (
          <Modal close_modal={close} visible={visible} {...props}>
            {children}
          </Modal>
        ),
    [hide, visible]
  )

  return [show, hide, RenderModal]
}

export function useAsyncModal() {
  const [visible, set_visible] = useState(false)
  const resolve = useRef(null)
  const reject = useRef(null)
  const [children_props, set_children_props] = useState(null)

  const show = (children_props) =>
    new Promise((res, rej) => {
      resolve.current = res
      reject.current = rej
      set_children_props(children_props)
      set_visible(true)
    })

  const modal_props = useMemo(
    () => ({
      visible,
      close_modal: () => {
        visible && reject.current()
        set_visible(false)
      },
      children_props: {
        resolve: (p) => {
          visible && resolve.current(p)
          set_visible(false)
        },
        reject: (p) => {
          visible && reject.current(p)
          set_visible(false)
        },
        ...children_props,
      },
    }),
    [visible, children_props]
  )

  return [show, modal_props]
}

export function useConfirmationModal() {
  const [show_async_modal, modal_props] = useAsyncModal()

  const confirmation_modal_props = useMemo(
    () => ({
      ...modal_props,
      sentiment: 'negative',
      children: ({
        resolve,
        reject,
        title = 'Delete',
        delete_button = 'Delete',
        text = 'Do you really want to delete this item?',
      }) => (
        <>
          <header>
            <header>{title}</header>
          </header>
          <main>{text}</main>
          <footer>
            <NewButton type='primary' sentiment='negative' onClick={resolve}>
              {delete_button}
            </NewButton>
            <NewButton
              type='secondary'
              sentiment='neutral'
              onClick={() => reject()}
            >
              Close
            </NewButton>
          </footer>
        </>
      ),
    }),
    [modal_props]
  )

  return [show_async_modal, confirmation_modal_props]
}
