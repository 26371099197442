import React from 'react'
import ReactDOM from 'react-dom'

import './index.css'
import App from './App'

import './style/general.scss'
import './gestalt.scss'

declare const window: any

window.toggleFullscreen = () =>
  document.fullscreenElement
    ? document.exitFullscreen()
    : document.documentElement.requestFullscreen()

class ErrorBoundary extends React.Component {
  componentDidCatch(error, info) {
    if (window.on_error_boundary_error) {
      window.on_error_boundary_error(error, info)
    }
  }

  render() {
    return <>{this.props.children}</>
  }
}

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
)

// import React from 'react'
// import ReactDOM from 'react-dom'
// import './index.css'
// // import App from './App'
// import reportWebVitals from './reportWebVitals'
//
// ReactDOM.render(
//   <React.StrictMode>abc</React.StrictMode>,
//   document.getElementById('root')
// )
//
// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
