import React, { useState, useEffect } from 'react'

function useStorageState(defaultValue, key, store) {
  const [value, set_value] = useState(
    Object.keys(store).includes(key)
      ? JSON.parse(store.getItem(key))
      : defaultValue
  )
  useEffect(() => {
    store.setItem(key, JSON.stringify(value))
  }, [value, key, store])

  return [value, set_value]
}

export default function useLocalStorageState(defaultValue, key) {
  return useStorageState(defaultValue, key, localStorage)
}

export function useSessionStorageState(defaultValue, key) {
  return useStorageState(defaultValue, key, sessionStorage)
}
