import React from 'react'
import { withRouter, useLocation } from 'react-router-dom'
import classnames from 'classnames'
import AsyncSVG from '@base_ui/components/AsyncSVG'
import { useAuthorize } from '@base_ui/hooks/auth'

import './NavItem.scss'

export default withRouter(
  ({
    linkTitle,
    linkIcon,
    disabled,
    exact,
    route,
    history,
    required_roles,
    any_role,
    allowed_user_ids,
    collapsed,
  }) => {
    const role_permitted = useAuthorize({
      required_roles,
      any_role,
      allowed_user_ids,
    })
    const location = useLocation()

    if (!role_permitted) return null

    const active = exact
      ? location.pathname === route
      : location.pathname.startsWith(route)

    let icon = <span className='material-icons'>{linkIcon}</span>
    if (linkIcon.startsWith('/')) {
      icon = <img src={linkIcon} alt='' />
    } else if (linkIcon.startsWith('@')) {
      icon = <AsyncSVG src={linkIcon} />
    }

    return (
      <button
        type='button'
        className={classnames('NavItem', {
          active,
          disabled,
        })}
        onClick={() =>
          history.push({ pathname: route, search: location.search })
        }
        disabled={active || disabled}
        title={collapsed ? linkTitle : null}
      >
        {icon}
        <div className='title'>{linkTitle}</div>
      </button>
    )
  }
)
