export async function import_mod_from_project_structure(mod_str) {
  let mod
  // We have many ifs and imports with explicit prefixes (like @gestalt/project/src) for webpack to properly
  // list which modules are importable
  if (mod_str.startsWith('.')) {
    if (mod_str.startsWith('./views/')) {
      mod = {
        ...(await import(
          `../views/${mod_str.replace('./views/', '').split('?')[0]}`
        )),
      }
    } else if (mod_str.startsWith('./entrypoints/')) {
      mod = {
        ...(await import(
          `../entrypoints/${
            mod_str.replace('./entrypoints/', '').split('?')[0]
          }`
        )),
      }
    } else if (mod_str.startsWith('./branding/')) {
      mod = {
        ...(await import(
          `../branding/${mod_str.replace('./branding/', '').split('?')[0]}`
        )),
      }
    } else if (mod_str.startsWith('./reducers/')) {
      mod = {
        ...(await import(
          `../reducers/${mod_str.replace('./reducers/', '').split('?')[0]}`
        )),
      }
      // } else if (mod_str.startsWith('./entrypoints/')) {
      //   mod = {
      //     ...(await import(
      //       `../entrypoints/${
      //         mod_str.replace('./entrypoints/', '').split('?')[0]
      //       }`
      //     ))
      //   }
    } else {
      throw Error(`Unknown module prefix: ${mod_str}`)
    }
  } else if (mod_str.indexOf('project/') !== -1) {
    mod = {
      ...(await import(
        `@gestalt/project/src/${
          mod_str.replace('@gestalt/project/src/', '').split('?')[0]
        }`
      )),
    }
  } else if (mod_str.indexOf('common/') !== -1) {
    mod = {
      ...(await import(
        `@gestalt/common/src/${
          mod_str.replace('@gestalt/common/src/', '').split('?')[0]
        }`
      )),
    }
  } else {
    throw Error(`Unknown module location: ${mod_str}`)
  }
  return mod
}
