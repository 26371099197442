import React, { forwardRef } from 'react'
import classnames from 'classnames'
import BaseAsyncButton from './BaseAsyncButton'
import CircularProgress from './CircularProgress'

import './NewButton.scss'

const NewButton = forwardRef(function NewButton(
  {
    type = 'primary',
    sentiment = 'positive',
    simple = false,
    className = '',
    progress_style = ['bar', 'number'], // 'bar', 'number' or 'circle'
    small = false,
    medium = false,
    full_width = false,
    icon,
    startIcon,
    endIcon,
    async_running_icon = null,
    children,
    async_running_children = null,
    button_type = 'button', // Change this to 'submit' if it is the primary button in a form
    ...additional_props
  },
  ref
) {
  return (
    <BaseAsyncButton
      ref={ref}
      className={classnames(
        'NewButton',
        className,
        type,
        sentiment,
        progress_style,
        {
          small,
          medium,
          'icon-only': !children && !async_running_children,
          full_width,
        }
      )}
      type={button_type}
      {...additional_props}
    >
      {({ async_running, has_progress, progress, cancelable }) => (
        <>
          <div className='icon'>
            {(async_running && async_running_icon
              ? async_running_icon
              : icon) && (
              <span className='material-icons'>
                {async_running && async_running_icon
                  ? async_running_icon
                  : icon}
              </span>
            )}
            {has_progress && progress_style.includes('circle') && (
              <CircularProgress
                sentiment={cancelable && async_running ? 'negative' : sentiment}
                progress={progress}
                radius={small ? 12 : medium ? 14 : 15}
              />
            )}
          </div>

          {!!children && !async_running_children && (
            <main>
              {startIcon && !icon && (
                <div className='icon left'>{startIcon}</div>
              )}
              <div>
                {async_running && async_running_children
                  ? async_running_children
                  : children}
              </div>
              {endIcon && !icon && <div className='icon right'>{endIcon}</div>}
            </main>
          )}
          {has_progress &&
            progress >= 0 &&
            progress_style.includes('number') && (
              <span className='progress-number'>
                {(progress * 100).toFixed(0)}%
              </span>
            )}

          {has_progress && progress_style.includes('bar') && (
            <div
              className='progress-overlay'
              style={
                progress > 0
                  ? {
                      transform: `scaleX(${Math.min(
                        1,
                        Math.max(0, progress)
                      )})`,
                    }
                  : null
              }
            />
          )}
        </>
      )}
    </BaseAsyncButton>
  )
})

export default NewButton
