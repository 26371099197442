import React, { cloneElement } from 'react'
import Button from '@gestalt/common/src/components/ui_elements/NewButton'
import { toast } from '@gestalt/common/src/components/ToastContainer'
import {
  useAuth,
  useAuthorize,
  useAuthorizeRole,
  useAuthorizeUserId,
  remove_url_auth_params,
} from '@gestalt/framework/src/hooks/auth'

export const NotLoggedInError = ({ error_children }) => {
  const auth = useAuth()
  return (
    <>
      {error_children}
      <Button onClick={() => auth.signinRedirect()} className='login-button'>
        Login
      </Button>
    </>
  )
}

export function BaseAuthorizeGate({
  children,
  permission_granted,
  redirect_to_login = false,
  error_component = null,
  show_login_button = false,
  error_props = null,
  show_error_toast = true,
}) {
  const auth = useAuth()

  switch (auth.activeNavigator) {
    case 'signinSilent':
      return <div className='center loading sign-in'>Signing you in...</div>
    case 'signoutRedirect':
      return <div className='center loading sign-out'>Signing you out...</div>
  }

  if (auth.isLoading) {
    return <div className='center loading'>Loading user...</div>
  }

  if (auth.error) {
    if (auth.error.message === 'No matching state found in storage') {
      auth.signinSilent()
      return <div className='center loading sign-in'>Signing you in...</div>
    }

    if (auth.error.message === 'Session not active') {
      if (redirect_to_login) {
        auth.signinRedirect()
        return <div className='center loading'>Redirecting to login...</div>
      } else {
        auth.removeUser()
      }
    }

    // Failed to fetch (?)

    console.error(auth.error)
    if (show_error_toast && (error_props || error_component)) {
      toast.error(auth.error.message)
    }
    if (error_props) return cloneElement(children, { ...error_props })
    if (error_component) return error_component()
    return (
      <div className='center error'>
        <h3>Authentication Error</h3>
        <span>{auth.error.cause}</span>
        {auth.error.message}
      </div>
    )
  }

  if (permission_granted) return children

  if (redirect_to_login) {
    auth.signinRedirect()
    return <div className='center loading'>Redirecting to login...</div>
  }

  if (error_props) return cloneElement(children, { ...error_props })

  if (show_login_button && !auth.isAuthenticated)
    return NotLoggedInError({
      error_children: error_component && error_component(),
    })
  return error_component && error_component()
}

export function Authorize({
  required_roles = [],
  any_role = [],
  allowed_user_ids = [],
  ...props
}) {
  const permission_granted = useAuthorize({
    required_roles,
    any_role,
    allowed_user_ids,
  })
  return (
    <BaseAuthorizeGate permission_granted={permission_granted} {...props} />
  )
}

export function AuthorizeRole({
  required_roles = [],
  any_role = [],
  ...props
}) {
  const permission_granted = useAuthorizeRole(required_roles, any_role)
  return (
    <BaseAuthorizeGate permission_granted={permission_granted} {...props} />
  )
}

export function AuthorizeUserId({ allowed_user_ids = [], ...props }) {
  const permission_granted = useAuthorizeUserId(allowed_user_ids)
  return (
    <BaseAuthorizeGate permission_granted={permission_granted} {...props} />
  )
}
