import React from 'react'
import {
  toast,
  ToastContainer as ToastContainerOriginal,
  ToastContainerProps,
} from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
import './ToastContainer.scss'

export const ToastContainer = (props: ToastContainerProps) => {
  return <ToastContainerOriginal position='top-center' {...props} />
}

export { toast }
