import React, { useMemo } from 'react'
import { User } from 'oidc-client-ts'
import { AuthContextProps, useAuth as useOIDCAuth } from 'react-oidc-context'
import {
  authentication_mocked,
  get_mock_auth_context_props,
  useAuthMock,
} from './auth_mock'

export function authentication_enabled(): boolean {
  return process.env.UI_OIDC_AUTHORITY !== ''
}

/**
 * useAuth hook similar which can mock a user
 *
 * @export
 * @returns {AuthContextProps}
 */
export function useAuth(): AuthContextProps {
  // I know it is not usually recommended to use hooks conditionally, but in this case the condition is "static" (env var)
  if (!authentication_enabled()) {
    return get_mock_auth_context_props(
      null,
      async () => null,
      async () => {}
    )
  }
  if (authentication_mocked()) return useAuthMock()
  return useOIDCAuth()
}

/**
 * Use this function to get the user outside of the AuthProvider context
 *
 * @returns User
 */
export function getUser(): User | null {
  const oidcStorage = sessionStorage.getItem(
    `oidc.user:${process.env.UI_OIDC_AUTHORITY}:${process.env.UI_OIDC_CLIENT_ID}`
  )
  if (!oidcStorage) {
    return null
  }

  return User.fromStorageString(oidcStorage)
}

/**
 * A custom type guard function that determines whether
 * `value` is an array that only contains strings.
 *
 * @param {unknown} value
 * @returns {value is string[]}
 */
function isStringArray(value: unknown): value is string[] {
  return (
    Array.isArray(value) &&
    value.every((element) => typeof element === 'string')
  )
}

export function check_role(
  required_role: string,
  auth: AuthContextProps
): boolean {
  if (!auth.user?.profile?.roles || !isStringArray(auth.user.profile.roles)) {
    console.warn('No role information in ID token.')
    return false
  }

  // * as role allows EVERYTHING
  if (auth.user.profile.roles.includes('*')) return true

  // Exact match
  return auth.user.profile.roles.includes(required_role)
}

export function authorize_role(
  required_roles: string[] | undefined,
  any_role: string[] | undefined,
  auth: AuthContextProps
): boolean {
  let result = !authentication_enabled() || auth.isAuthenticated
  if (!!required_roles && required_roles.length > 0) {
    result &&= required_roles.every((role) => check_role(role, auth))
  }
  if (!!any_role && any_role.length > 0) {
    result &&= any_role.some((role) => check_role(role, auth))
  }
  return result
}

export function authorize_user_id(
  allowed_user_ids: string[] | undefined,
  auth: AuthContextProps
): boolean {
  if (
    !authentication_enabled() ||
    !allowed_user_ids ||
    allowed_user_ids.length < 1
  ) {
    return true
  } else if (auth.isAuthenticated && auth.user?.profile?.sub) {
    if (allowed_user_ids.includes('*')) return true // Simple way to authorize all logged in users
    return allowed_user_ids.includes(auth.user?.profile?.sub)
  }
  return false
}

interface AuthorizeProps {
  required_roles?: string[]
  any_role?: string[]
  allowed_user_ids?: string[]
  auth: AuthContextProps
}

export function authorize(props: AuthorizeProps): boolean {
  return (
    authorize_role(props.required_roles, props.any_role, props.auth) &&
    authorize_user_id(props.allowed_user_ids, props.auth)
  )
}

export type UseAuthorizeProps = Omit<AuthorizeProps, 'auth'>

export function useAuthorize(props: UseAuthorizeProps) {
  const auth = useAuth()
  return useMemo(
    () =>
      authorize({
        auth: auth,
        ...props,
      }),
    [props, auth]
  )
}

export function useAuthorizeRole(
  required_roles?: string[],
  any_role?: string[]
): boolean {
  return useAuthorize({ required_roles, any_role })
}

export function useAuthorizeUserId(allowed_user_ids?: string[]): boolean {
  return useAuthorize({ allowed_user_ids })
}

export function remove_url_auth_params(): void {
  // https://.../...?state=...&session_state=...&iss=...&code=...
  const url = new URL(window.location.href)
  url.searchParams.delete('state')
  url.searchParams.delete('session_state')
  url.searchParams.delete('iss')
  url.searchParams.delete('code')
  window.history.replaceState({}, document.title, url.href)
}
