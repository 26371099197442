import React from 'react'
import classnames from 'classnames'
import BaseAsyncButton from './BaseAsyncButton'
import CircularProgress from './CircularProgress'

import './IconTextButton.scss'

export default function IconTextButton({
  type = 'primary',
  sentiment = 'positive',
  className = '',
  small = false,
  medium = false,
  icon,
  async_running_icon = null,
  children,
  async_running_children = null,
  button_type = 'button', // Change this to 'submit' if it is the primary button in a form
  ...additional_props
}) {
  return (
    <BaseAsyncButton
      className={classnames('IconTextButton', className, type, sentiment, {
        small,
        medium,
        'icon-only': !children && !async_running_children,
      })}
      type={button_type}
      {...additional_props}
    >
      {({ async_running, has_progress, progress, cancelable }) => (
        <>
          <div className='icon'>
            {(async_running && async_running_icon
              ? async_running_icon
              : icon) && (
              <span className='material-icons'>
                {async_running && async_running_icon
                  ? async_running_icon
                  : icon}
              </span>
            )}
            {has_progress && (
              <CircularProgress
                sentiment={cancelable && async_running ? 'negative' : sentiment}
                progress={progress}
                radius={small ? 12 : medium ? 14 : 15}
              />
            )}
          </div>
          <main>
            {async_running && async_running_children
              ? async_running_children
              : children}
          </main>
        </>
      )}
    </BaseAsyncButton>
  )
}
