import React, { PureComponent } from 'react'

function ViewLoadingIndicator(props) {
  return process.env.UI_SHOW_ASYNC_LOADING_TEXT !== '0' ? (
    <div>Loading...</div>
  ) : null
}

export function ViewNotFoundIndicator(props) {
  return <div>View not found...</div>
}

export default class AsyncComponent extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      Component: null,
      err: null,
      shouldRender: props.hasOwnProperty('render') ? props.render : true,
    }
  }

  componentDidMount() {
    if (!this.state.Component) {
      this.props
        .moduleProvider()
        .then((mod) =>
          this.setState({ Component: this.props.componentSelector(mod) })
        )
        .catch((err) => this.setState({ err }))
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.moduleProvider !== this.props.moduleProvider) {
      this.props
        .moduleProvider()
        .then((mod) =>
          this.setState({ Component: this.props.componentSelector(mod) })
        )
        .catch((err) => this.setState({ err }))
    }
  }

  render() {
    const { Component, err, shouldRender } = this.state

    if (err) {
      return <ViewNotFoundIndicator />
    } else if (!Component) {
      return <ViewLoadingIndicator />
    } else if (!shouldRender) {
      return null
    }
    return <Component />
  }
}
